const namespace = 'wp-lemon';


/**
 * @api {function} findParentBlock(el) findParentBlock
 * @param {Element} el the element that fired the event 
 * @returns {Element} the parent element with the class .acf-block
 */
function findParentBlock(el) {
   // traverse up the dom to find the element with the class .acf-block
   let parent = el;

   while (el && !el.classList.contains('acf-block')) {
      parent = el.parentElement;
   }

   return parent;
}


/**
 * Function to fire custom events
 * 
 * @param {string} eventName event name prefixed with namespace
 * @param {string} module module name
 * @param {object} details data to pass to event 
 */
function trigger(eventName, module, details = false) {
   const name = `${namespace}/${module}/${eventName}`;
   const event = new CustomEvent(name, {
      bubbles: true,
      cancelable: true,
      detail: details
   });

   document.dispatchEvent(event);
}


export { findParentBlock, trigger };