import { trigger } from "./api/helpers";

const module = 'dropdown';

export default () => {
    const subMenus = document.querySelectorAll('.navbar .dropdown-menu');
    const dropdowns = document.querySelectorAll('.dropdown-toggle');

    dropdowns.forEach((dropdown) => {
        dropdown.addEventListener('click', function (e) {
            trigger('fire', module, {
                element: dropdown
            });
            e.preventDefault();

            var subMenu = dropdown.parentElement.querySelector('.dropdown-menu');
            var status = dropdown.getAttribute('aria-expanded');

            subMenus.forEach((loopedMenu) => {

                if (subMenu === loopedMenu) {
                    return;
                }

                if (dropdown.parentElement.parentElement == loopedMenu) {
                    return;
                }

                //loopedMenu.parentElement.querySelector('.dropdown-item').setAttribute('aria-expanded', 'false');
                loopedMenu.classList.remove('show');
                loopedMenu.setAttribute('aria-expanded', 'false');
                loopedMenu.parentElement.querySelector('a').setAttribute('aria-expanded', 'false');
            });

            if (status == 'false') {
                trigger('show', module);
                subMenu.classList.add('show');
                subMenu.setAttribute('aria-expanded', 'true');
                dropdown.setAttribute('aria-expanded', 'true');
            } else {
                trigger('hide', module);
                subMenu.classList.remove('show');
                subMenu.setAttribute('aria-expanded', 'false');
                dropdown.setAttribute('aria-expanded', 'false');
            }
        });
    });

    /**
     * Closes dropdowns when hitting escape
     */
    window.addEventListener('keydown', function (event) {
        if (event.key !== 'Escape') {
            return;
        }

        subMenus.forEach((allSubMenus) => {
            var aElement = allSubMenus.parentElement.querySelector('a');
            allSubMenus.classList.remove('show');
            allSubMenus.setAttribute('aria-expanded', 'false');
            aElement.setAttribute('aria-expanded', 'false');
        });
    });

    // listen for dispatch event from search.js
    window.addEventListener('searchOpen', function (event) {
        subMenus.forEach((allSubMenus) => {
            var aElement = allSubMenus.parentElement.querySelector('a');
            allSubMenus.classList.remove('show');
            allSubMenus.setAttribute('aria-expanded', 'false');
            aElement.setAttribute('aria-expanded', 'false');
        });
    });

    /**
     *
     * Closes dropdowns when clicking outside a menu.
     */
    window.addEventListener('mouseup', function (event) {
        subMenus.forEach((allSubMenus) => {
            var aElement = allSubMenus.parentElement.querySelector('a');
            var navbar = document.querySelector('.navbar');
            if (event.target != allSubMenus && !navbar.contains(event.target)) {
                allSubMenus.classList.remove('show');
                allSubMenus.setAttribute('aria-expanded', 'false');
                aElement.setAttribute('aria-expanded', 'false');
            }
        });
    });
};
