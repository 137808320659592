// Import Swiper and modules
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/modules';

var sliders = [];
export default function blockSlider() {
    const sliderElements = document.querySelectorAll('.slider-el');
    if (!sliderElements) return;

    sliderElements.forEach((item) => {
        var autoplay = item.dataset.autoplay,
            effect = item.dataset.effect,
            autoplayDelay = item.dataset.autoplayDelay,
            pagination = item.querySelector('.slider__pagination'),
            next_el = item.querySelector('.swiper-button-next'),
            prev_el = item.querySelector('.swiper-button-prev');

        var slider = new Swiper(item, {
            loop: true,
            preloadImages: false,
            modules: [Navigation, Pagination, Autoplay, EffectFade],
            effect: effect,
            fadeEffect: {
                crossFade: true,
            },
            speed: 800,
            disableOnInteraction: false,
            pagination: {
                el: pagination,
                type: 'bullets',
                clickable: true,
            },
            navigation: {
                nextEl: next_el,
                prevEl: prev_el,
            },
        });

        if (autoplay) {
            slider.params.autoplay.enabled = true;
            slider.params.autoplay.delay = autoplayDelay;
            slider.autoplay.start();
        }
        sliders.push(slider);
    });
    return sliders;
}