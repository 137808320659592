import { lazyLoadFunc } from './lazyLoad';
import animations from './animations';
import menu, { setNavHeight } from './menu';
import contactButtons from './contact-buttons';
import search from './search';
import { archiveListeners } from './archive';
import sharePopup from './share-popup';
import SmoothScroll from './smoothScroll';
import { a11yBar, enhancedTabExperience, skiplinks } from './a11y';
import dropdown from './dropdown';
import blockSlider from './block-slider';
import setupCookieBar from './cookiebar';
import scriptInjector from './script-injector';
import faqSearchListeners from './faq-search';
import blockCarousel from './block-carousel';


export default function frontend() {
    enhancedTabExperience();
    skiplinks();
    scriptInjector();
    setupCookieBar();
    SmoothScroll();
    a11yBar();
    lazyLoadFunc();
    blockSlider();
    archiveListeners();
    blockCarousel();
    animations();
    menu();
    setNavHeight();
    contactButtons();
    search();
    sharePopup();
    dropdown();
    faqSearchListeners();
}
