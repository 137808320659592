var accessibilitySettings = {};

function enhancedTabExperience() {
    var bodyClassTabbing = 'js-aiiy--tabbing';

    window.addEventListener('keydown', function (e) {
        return handleTab(e);
    });

    window.addEventListener('mousedown', function () {
        return handleMouseDown();
    });

    function handleTab(press) {
        'Tab' === press.key && (document.body.classList.add(bodyClassTabbing), window.removeEventListener('keydown', handleTab));
    }

    function handleMouseDown() {
        document.body.classList.remove(bodyClassTabbing), window.removeEventListener('mousedown', handleMouseDown);
    }
}

/**
 * Callback to fix skip links focus.
 *
 * @since 2.2.0
 */

function skiplinks() {
    'use strict';
    var fragmentID = location.hash.substring(1);
    if (!fragmentID) {
        return;
    }

    var element = document.getElementById(fragmentID);
    if (element) {
        if (false === /^(?:a|select|input|button|textarea)$/i.test(element.tagName)) {
            element.tabIndex = -1;
        }
        element.focus();
    }
}

window.addEventListener('hashchange', skiplinks, false);

function a11yBar() {
    const aiiyBar = document.querySelector('.a11y-bar');

    if (!aiiyBar) {
        return;
    }

    const textBtn = document.querySelector('.js-a11y--text');
    const textBtnTxt = textBtn.getElementsByTagName('span');
    const contrastBtn = document.querySelector('.js-a11y--contrast');
    const contrastBtnTxt = contrastBtn.getElementsByTagName('span');
    const app = document.documentElement;

    var localstorageSettings = JSON.parse(localStorage.getItem('accessibilitySettings'));

    if (localstorageSettings !== null) {
        if (localstorageSettings.largeText == true) {
            textBtnTxt[0].textContent = wplemon.i18n.smaller;
        }

        if (localstorageSettings.highContrast == true) {
            contrastBtnTxt[0].textContent = wplemon.i18n.off;
        }
    }

    textBtn.addEventListener('click', (event) => {
        var span = event.currentTarget.getElementsByTagName('span');
        app.classList.toggle('js-aiiy--large-text');

        if (app.classList.contains('js-aiiy--large-text')) {
            span[0].textContent = wplemon.i18n.smaller;
            accessibilitySettings['largeText'] = true;
        } else {
            span[0].textContent = wplemon.i18n.larger;
            accessibilitySettings['largeText'] = false;
        }

        saveA11ySettingtoLocalStorage();
    });

    contrastBtn.addEventListener('click', (event) => {
        var span = event.currentTarget.getElementsByTagName('span');
        app.classList.toggle('js-aiiy--contrast');
        if (app.classList.contains('js-aiiy--contrast')) {
            span[0].textContent = wplemon.i18n.off;
            accessibilitySettings.highContrast = true;
        } else {
            span[0].textContent = wplemon.i18n.contrast;
            accessibilitySettings.highContrast = false;
        }

        saveA11ySettingtoLocalStorage();
    });

    function saveA11ySettingtoLocalStorage() {
        localStorage.setItem('accessibilitySettings', JSON.stringify(accessibilitySettings));
    }
}

export { skiplinks, enhancedTabExperience, a11yBar };
