
const next = document.querySelector('.af-next-button');
let checked = 0;
let fields;
let moduleFields;
let stats;
export default function form() {
   // get .af-submit element

   const submit = document.querySelector('.af-submit');



   if (!submit) {
      return;
   }
   const submitWrap = document.createElement('div');
   submit.parentNode.insertBefore(submitWrap, submit);
   // wrap all element in submit in a div

   submitWrap.classList.add('af-submit-wrap');


   stats = document.createElement('div');
   stats.classList.add('af-stats');

   submitWrap.appendChild(stats);
   //submitWrap.appendChild(submit);
   updateStats();

   fields = acf.getFields({
      type: 'true_false'
   });

   var instance = new acf.Model({
      events: {
         'change input[type="radio"]': 'onChangeRadio',
         'change input[type="checkbox"]': 'onChangeCheckBox',
      },
      onChangeRadio: function (e, $el) {
         fields = acf.getFields({
            type: 'radio'
         });
         checked = 0;
         updateStats();
      },
      onChangeCheckBox: function (e, $el) {
         checked = 0;
         updateStats();
      }
   });



   function updateStats() {

      fields = acf.getFields({
         type: 'true_false',
      });

      moduleFields = fields.filter(function (field) {
         var element = field.$el[0];
         return !element.parentNode.parentNode.parentNode.classList.contains('acf-hidden') && element.parentNode.parentNode.parentNode.classList.contains('module-wrapper');
      });

      moduleFields.forEach(function (field) {
         if (field.$input().is(':checked')) {
            checked++;
         }
      });
      console.log(checked);
      if (0 === checked) {
         submitWrap.classList.remove('af-submit-wrap--visible');
      } else {
         submitWrap.classList.add('af-submit-wrap--visible');
      }

      // update stats
      stats.innerHTML = checked + ' van de ' + moduleFields.length + ' vakken geselecteerd.';
   }


}


