import Swiper from 'swiper';
import { Navigation, Scrollbar, Pagination, Mousewheel } from 'swiper/modules';

export default () => {
   const sliderElements = document.querySelectorAll('.js-carousel');
   if (!sliderElements) return;

   sliderElements.forEach((item) => {

      const paginationStyle = item.dataset.paginationStyle;
      const scrollBarEl = item.querySelector('.swiper-progress');
      const paginationEL = item.querySelector('.swiper-pagination');
      const swiper = new Swiper(item, {
         modules: [Navigation, Scrollbar, Mousewheel, Pagination],
         preloadImages: true,
         lazyPreloadPrevNext: 4,
         mousewheel: {
            forceToAxis: true,
         },
         slidesPerView: 1.1,
         centeredSlides: true,
         spaceBetween: 24,
         freeMode: true,
         speed: 400,
         initialSlide: 1,
         disableOnInteraction: false,
         rewind: true,
         init: false,
         breakpoints: {
            768: {
               slidesPerView: 1,
            },
         },
      });

      if ('dots' == paginationStyle) {
         swiper.params.pagination.el = paginationEL;
         swiper.params.pagination.clickable = true;
      } else {
         swiper.params.scrollbar.el = scrollBarEl;
         swiper.params.scrollbar.draggable = true;
      }

      swiper.init();

   });
}
