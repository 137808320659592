import AOS from 'aos/dist/aos.esm';
export default function animations() {
    var reduced = window.matchMedia('(prefers-reduced-motion: reduce)');

    AOS.init({
        once: true,
        duration: 500,
        delay: 0,
        easing: 'ease',
        disable: reduced.matches,
    });
}
