import EventModal from "../../resources/assets/scripts/components/eventModal";

export default function blockModuleEvents() {
   // get all elements with class .crd--portfolio
   const links = document.querySelectorAll('.js-date-popup');

   links.forEach((link) => {
      // add event listener to each element
      link.addEventListener('click', (e) => {
         e.preventDefault();
         // get the id from the data attribute
         const id = link.dataset.id;
         // call the modal function
         EventModal(id);
      });
   });
}