import Modal from 'bootstrap/js/dist/modal';

export default async function EventModal(id) {
   let
      parameters = {},
      url = wplemon.ajax,
      nonce = wplemon.ajax_nonce,
      data = new FormData();


   parameters.post_id = id;

   data.append('action', 'load_event_modal');
   data.append('nonce', nonce);
   data.append('parameters', JSON.stringify(parameters));

   try {

      const response = await fetch(url, {
         method: 'POST',
         credentials: 'same-origin',
         body: data,
      });

      const body = document.querySelector('body');
      const res = await response.json();

      // remove old modal
      const oldModal = document.querySelector('#eventModal');
      if (oldModal) {
         oldModal.remove();
      }

      body.insertAdjacentHTML('beforeend', res);
      const applicationModal = new Modal('#eventModal')
      applicationModal.show()

   } catch (err) {
      console.error(err);
   }
}
