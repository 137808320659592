import throttle from 'lodash.throttle';
export default function contactButtons() {
    const contactButtons = document.querySelector('.contact-buttons');
    const buttons = document.querySelectorAll('.contact-button');
    var lastScrollTop = 0;

    if (!contactButtons) {
        return;
    }

    buttons.forEach((button) => {
        button.addEventListener('click', () => {
            button.classList.toggle('contact-button--active');
        });
    });

    window.addEventListener(
        'scroll',
        throttle(
            function () {
                var st = window.scrollY;

                if (Math.abs(lastScrollTop - st) <= 200) return;

                buttons.forEach((button) => {
                    button.classList.remove('contact-button--active');
                });

                lastScrollTop = st;
            },
            250,
            { leading: true, trailing: true },
        ),
    );
}
