export default function scriptInjector() {
   document.addEventListener("wp-lemon/cookiebar/cookiesAllowed", function (event) {
      ajaxScriptInjector();
   });
}

/**
 * Starts the ajax call and injects the scripts when the response is ready.
 * 
 * @returns void
 */
async function ajaxScriptInjector() {

   let data = new FormData();
   data.append('action', 'ajax_script_injector');
   data.append('nonce', wplemon.ajax_nonce);
   try {

      const response = await fetch(wplemon.ajax, {
         method: 'POST',
         credentials: 'same-origin',
         body: data,
      });

      const res = await response.json();

      if ('error' === res.status) {
         return;
      }

      if (res.scripts.head) {
         scriptParser(res.scripts.head, 'head');
      }

      if (res.scripts.body) {
         scriptParser(res.scripts.body, 'body');
      }

   } catch (err) {
      console.error(err);
   }
}

/**
 * Parse scripts and inject them into the DOM.
 * 
 * @param {string} response   The response from the ajax call.
 * @param {string} location   The location to inject the scripts.
 */
function scriptParser(response, location) {
   const doc = document.createRange().createContextualFragment(response);
   const scripts = doc.querySelectorAll('script, noscript');
   scripts.forEach(script => {

      if ('head' === location) {
         document.head.appendChild(script);
      } else {
         document.body.appendChild(script);
      }
   });
}