import throttle from 'lodash.throttle';
import { trigger } from './api/helpers';

const module = 'menu';
export default function menu() {
    var navBar = document.querySelector('.navbar'),
        elClassHidden = 'navbar--hidden',
        elClassScroll = 'navbar--scroll',
        elClassTop = 'navbar--top',
        navCollapse = document.querySelector('.offcanvas'),
        throttleTimeout = 200,
        subMenus = document.querySelectorAll('.navbar .dropdown-menu');

    const myOffcanvas = document.getElementById('offcanvasNavbar')

    var hasElementClass = function (element, className) {

        if (!element) return false;

        return element.classList ? element.classList.contains(className) : new RegExp('(^| )' + className + '( |$)', 'gi').test(element.className);
    };

    if (!navBar) return false;

    if (myOffcanvas) {
        myOffcanvas.addEventListener('show.bs.offcanvas', event => {
            myOffcanvas.classList.add('offcanvas--show');
        })

        myOffcanvas.addEventListener('hidden.bs.offcanvas', event => {
            myOffcanvas.classList.remove('offcanvas--show');
        })
    }

    var currentScrollPosition = 0,
        previousScrollPosition = 0,
        distanceScrolled = 0,
        distanceToTopViewport;

    window.addEventListener('scroll', throttle(menuScroller, throttleTimeout, { leading: true, trailing: true }));

    function menuScroller() {
        currentScrollPosition = window.scrollY;
        distanceScrolled = previousScrollPosition - currentScrollPosition;
        distanceToTopViewport = navBar.getBoundingClientRect().top;

        if (currentScrollPosition <= 0) {
            navBar.classList.remove(elClassScroll);
        } else {
            navBar.classList.add(elClassScroll);
        }

        if (distanceToTopViewport <= 0) {
            navBar.classList.add(elClassTop);
        } else {
            navBar.classList.remove(elClassTop);
        }

        if (hasElementClass(navCollapse, 'collapsing') || hasElementClass(navCollapse, 'show')) {
            return;
        } else if (currentScrollPosition <= 0 && distanceToTopViewport >= 0) {
            // navbar is on top.
            navBar.classList.remove(elClassHidden);
        } else if (distanceScrolled > 100) {
            // scrolled up; navBar slides in
            navBar.classList.remove(elClassHidden);
        } else if (distanceScrolled < -200 && distanceToTopViewport <= 0) {
            // scrolled down; navBar slides away
            navBar.classList.add(elClassHidden);

            trigger('hide', module);

            subMenus.forEach(function (item) {
                var aElement = item.parentElement.querySelector('a');
                item.classList.remove('show');
                item.setAttribute('aria-expanded', 'false');
                aElement.setAttribute('aria-expanded', 'false');
            });
        }

        previousScrollPosition = currentScrollPosition;
    }
}

export function setNavHeight() {
    var navBar = document.querySelector('.navbar');
    if (!navBar) return false;
    var navHeight = navBar.offsetHeight;
    var sheet = document.getElementById('wplemon-inline-css').sheet.cssRules[0];
    sheet.style.setProperty('--nav-height', navHeight + 'px');
}
