import LazyLoad from 'vanilla-lazyload/dist/lazyload.min.js';
var lazyInstance;

/**
 * LazyLoad function that uses vanilla-lazyload.
 * Tries to use native lazy loading if browser supports it and is not safari.
 * 
 * @returns {LazyLoad} lazyInstance
 */
function lazyLoadFunc() {
    let use_native = true;

    if (navigator.userAgent.match(/(iPod|iPhone|iPad)/) || /^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
        use_native = false;
    }

    lazyInstance = new LazyLoad({
        elements_selector: '.lazyload',
        use_native: use_native,
    });

    return lazyInstance;
}

export { lazyLoadFunc, lazyInstance };
