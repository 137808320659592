import { trigger } from "./api/helpers";

const module = 'search';

export default function search() {
    const searchBar = document.querySelector('.navbar__search');
    const searchBtn = document.querySelector('#js-search-button');
    const liveSearch = document.querySelector('.searchwp-live-search-results');

    if (!searchBtn) return;

    searchBtn.addEventListener('click', function () {
        document.body.classList.toggle('search-active');
        searchBar.classList.toggle('active');

        if (searchBar.classList.contains('active')) {
            searchBar.querySelector('input').focus();
        }

        trigger('show', module);
    });


    /**
     * Closes search bar when hitting escape
     */
    window.addEventListener('keydown', function (event) {
        if (event.key !== 'Escape') {
            return;
        }

        searchBar.classList.remove('active');
        document.body.classList.remove('search-active');
    });

    /**
     *
     * Closes dropdowns when clicking outside a menu.
     */
    window.addEventListener('mouseup', function (event) {
        if (liveSearch && (event.target == searchBar || searchBar.contains(event.target))) {
            return;
        }

        if (event.target == searchBar || searchBar.contains(event.target) || event.target == searchBtn || searchBtn.contains(event.target)) {
            return;
        }

        searchBar.classList.remove('active');
        document.body.classList.remove('search-active');
    });

    // listen for wp-lemon/menu/hide event
    document.addEventListener(`wp-lemon/menu/hide`, function (event) {
        searchBar.querySelector('input').blur();
        searchBar.classList.remove('active');
        document.body.classList.remove('search-active');
    });
}