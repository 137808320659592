/**
 * Function to smoothly scroll to an anchor in the website.
 *
 */
export default function SmoothScroll() {
    const navbar = document.querySelector('#navbar');
    const navheight = navbar ? navbar.offsetHeight : 0;

    document.querySelectorAll('a[href*="#"]').forEach((trigger) => {
        console.log(trigger);
        if (trigger.getAttribute('target') === '_blank') {
            return;
        }

        if (trigger.parentElement.classList.contains('additional_information_tab')) {
            return;
        }

        if (trigger.classList.contains('js-no-smoothscroll')) {
            return;
        }

        let target = trigger.getAttribute('href');
        let hash = target.substring(target.indexOf('#'));

        if (hash === '#') {
            return;
        }

        trigger.addEventListener('click', (e) => {
            let target = document.querySelector(hash);

            if (!target) {
                return;
            }

            e.preventDefault();

            var elementPosition = target.getBoundingClientRect();

            window.scrollTo({
                top: elementPosition.top + window.scrollY - (navheight + 30),
                behavior: 'smooth',
            });
        });
    });
}
