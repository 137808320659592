export default function sharePopup() {
    const shareButtons = document.querySelectorAll('.share-buttons__link');

    if (!shareButtons) return;

    shareButtons.forEach((item) => {
        item.addEventListener('click', (event) => {
            if (event && window.innerWidth > 1024) {
                var target = event.currentTarget.href;
                var i = window.open(target, '_blank', 'height=350,width=500');
                i && (event && event.preventDefault(), i.focus());
            }
        });
    });
}
